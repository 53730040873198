




































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import { getDirective } from 'vue-debounce'

@Component({
  directives: {
    debounce: getDirective()
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  }
})
export default class CandidateUsers extends Vue {
  page = 1
  pageLength = 50
  totalItems = 0
  columns = [
    {
      label: 'Name',
      field: 'fullName',
    },
    {
      label: 'Email',
      field: 'email',
    },
    {
      label: 'Phone',
      field: 'phone',
    }
  ]

  // @ts-ignore
  rows: CandidateAPI.Candidate.SearchItem[] = []
  searchTerm = ''

  async created() {
    await this.getItems();
  }

  async getItems() {
    let data;
    if(this.searchTerm != null && this.searchTerm != "") {
      data = await this.$api.candidate.user.BasicSearch(this.searchTerm, this.page, this.pageLength);
    }else{
      data = await this.$api.candidate.user.BasicSearch(null, this.page, this.pageLength);
    }
    this.$set(this, "rows", data.items);
    this.$set(this, "totalItems", data.totalItems);
  }

  @Watch('page')
  async watchPage() {
    await this.getItems();
  }

  @Watch('pageLength')
  async watchPageLength() {
    await this.getItems();
  }

  async search() {
    const data = await this.$api.candidate.user.BasicSearch(this.searchTerm, this.page, this.pageLength);
    this.$set(this, "rows", data.items);
    this.$set(this, "totalItems", data.totalItems);
  }

  async goToUser(id: string) {
    let routeData = this.$router.resolve({name: 'candidate-user', params: {identifier: id}})
    window.open(routeData.href, '_blank');
  }
}
